<template>
  <div :class="$style.home">
    <div :class="$style.header">
      <div :class="$style.icon">
        <i class="ri-emotion-line" />
      </div>
      <div :class="$style.right">
        <p :class="$style.title">角色管理</p>
        <p :class="$style.dec">角色管理分为系统角色和自定义角色，通过对角色分配不同的权限，实现系统的精细化管理。</p>
      </div>
    </div>
    <div :class="$style.detail">
      <div :class="$style.left">
        <left ref="left" @change="onChange" @add="onAdd" @user="onUser" @close="onClose" @default="onDefault" />
      </div>
      <div :class="$style.right">
        <detail ref="detail" :data="data" :def="def" :user="user" @refresh="refresh" @ondelete="ondelete" />
      </div>
    </div>
  </div>
</template>

<script>
import detail from './detail.vue'
import left from './left.vue'

export default {
  name: 'Home',
  components: {
    left,
    detail,
  },
  data() {
    return {
      data: {
        name: null,
        describe: null,
        perms: [],
      },
      user: [],
      def: [],
    }
  },
  methods: {
    onChange(item) {
      this.data = item
      // this.$refs.detail.edit = false
    },
    onAdd() {
      this.$refs.detail.onAdd()
    },
    onClose() {
      this.$refs.detail.edit = false
    },
    onUser(e) {
      this.user = e
    },
    onDefault(e) {
      this.def = e
    },
    refresh() {
      this.$refs.left.refresh()
    },
    ondelete() {
      this.$refs.left.onDelete()
    },
  },
}
</script>

<style lang="less" module>
.home {
  .header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 90px;
    background: #fff;

    .icon {
      width: 50px;
      height: 50px;
      margin-left: 20px;
      text-align: center;
      background: #fafafa;
      border-radius: 25px;

      i {
        display: inline-block;
        margin-top: 10px;
        color: #000;
        font-size: 20px;
      }
    }

    .dec {
      color: #5c5c5c;
      font-size: 12px;
    }

    .right {
      flex: 1;
      padding-left: 12px;
    }

    .title {
      margin: 12px 0 4px 0;
      color: #000;
      font-size: 16px;
    }
  }

  .detail {
    display: flex;
    margin-top: 20px;
    min-height: calc(100vh - 262px);
  }

  .left {
    width: 200px;
    margin-right: 20px;
    background: #fff;
  }

  .right {
    flex: 1;
  }
}
</style>
