<template>
  <div :class="$style.home">
    <div :class="$style.title">
      角色列表
      <i v-permission="'system.permission.role-create'" class="ri-add-circle-line" @click="onAdd()" />
    </div>
    <div :class="$style.default">
      <div
        v-for="item in data"
        :key="item.id"
        :class="[$style.item, active === item.id ? $style.active : null]"
        @click="onChoose(item, true)"
      >
        <i :class="$style.icon" class="ri-user-line" />
        <span :class="$style.name" class="ellipsis">
          {{ item.name }}
        </span>
        <i :class="$style.fixed" class="ri-pushpin-line" />
      </div>
    </div>
    <div v-if="userData.length" :class="$style.line" />
    <div :class="$style.default">
      <div
        v-for="item in userData"
        :key="item.id"
        :class="[$style.item, active === item.id ? $style.active : null]"
        @click="onChoose(item, false)"
      >
        <i :class="$style.icon" class="ri-user-settings-line" />
        <span :class="$style.name" class="ellipsis">
          {{ item.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { roleService } from '@/service'

const service = new roleService()

export default {
  name: 'Home',
  data() {
    return {
      data: [],
      userData: [],
      active: null,
      // isAdd: false,
    }
  },
  created() {
    this.getUser()
    this.getDefault()
  },
  methods: {
    async getUser() {
      const { data } = await service.getListUser()
      this.userData = data
      this.$emit('user', data)
    },
    async getDefault(type) {
      const { data } = await service.getListDefault()
      this.data = data
      this.$emit('default', data)
      if (!type) {
        this.onChoose({ ...data[0] }, true)
      }
    },
    onChoose(item, type) {
      this.active = item.id
      this.$emit('change', { ...item, isDefault: type })
      this.$emit('close')
    },
    onAdd() {
      this.$emit('add')
    },
    // eslint-disable-next-line vue/no-unused-properties
    async refresh() {
      const { data } = await service.getListUser()
      this.userData = data
      this.$emit('user', data)
      data.map(item => {
        if (item.id === this.active) {
          this.onChoose({ ...item }, false)
        }
      })
    },
    // eslint-disable-next-line vue/no-unused-properties
    async onDelete() {
      const { data } = await service.getListUser()
      this.userData = data
      this.$emit('user', data)
      this.active = data[0].id
      data.map(item => {
        if (item.id === this.active) {
          this.onChoose({ ...item }, false)
        }
      })
    },
  },
}
</script>

<style lang="less" module>
.home {
  padding: 20px;

  .title {
    font-size: 16px;
    color: #000;
    position: relative;
    margin-bottom: 14px;

    i {
      position: absolute;
      right: 0;
      top: -3px;
      font-size: 20px;
      color: @primary-color;
    }
  }

  .default {
    .item {
      margin-bottom: 2px;
      width: 160px;
      height: 44px;
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 0 12px;
      justify-content: space-between;
      cursor: pointer;
      border-radius: 6px;
      .name {
        flex: 1;
      }

      .icon {
        font-size: 20px;
        color: #000;
        margin-right: 12px;
      }

      .fixed {
        font-size: 18px;
        color: #bbb;
      }
    }

    .active {
      background: #fafafa;
      color: @primary-color;
      .icon {
        color: @primary-color;
      }
    }
  }

  .line {
    width: 100%;
    border-top: 1px solid #eee;
    margin: 10px 0;
  }
}
</style>
