<template>
  <div :class="$style.detail">
    <div v-if="!edit" :class="$style.header">
      <i :class="$style.icon" class="ri-user-line" />
      <div :class="$style.name">
        <p>
          {{ data.name }}
          <i
            v-if="!data.isDefault"
            v-permission="'system.permission.role-edit'"
            class="ri-edit-2-line ml-10"
            @click="onEdit()"
          />
          <i
            v-if="!data.isDefault"
            v-permission="'system.permission.role-delete'"
            class="ri-delete-bin-7-line ml-10"
            @click="onDelete()"
          />
        </p>
        <p :title="data.describe" :class="$style.desc" class="ellipsis">{{ data.describe }}</p>
      </div>
      <div :class="$style.btn" @click="onInclude()">
        包含
        <span>{{ data.number }}</span>
        人
        <i class="ri-arrow-right-s-line" />
      </div>
    </div>
    <div v-else :class="$style.edit">
      <div :class="$style.box">
        <div class="layout">
          <div class="label">
            <span class="required">*</span>
            角色名称
          </div>
          <div class="value">
            <BjInput v-model="add.name" placeholder="请输入角色名称" />
          </div>
        </div>
        <div class="layout">
          <div class="label">角色描述</div>
          <div class="value" :class="$style.area">
            <a-textarea
              v-model="add.describe"
              :auto-size="{ minRows: 4, maxRows: 4 }"
              placeholder="请对角色进行详细描述"
              :max-length="120"
            />
            <span :class="$style.num">{{ add.describe ? add.describe.length : 0 }}/120</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!edit" :class="$style.tree">
      <div :class="$style.item">
        <a-tree
          v-model="resourceKeys"
          checkable
          disabled
          :tree-data="resource"
          :replace-fields="{ children: 'children', title: 'name', key: 'id' }"
          @check="onSelect"
        />
      </div>
      <div :class="$style.item">
        <a-tree
          v-model="systemKeys"
          checkable
          disabled
          :tree-data="system"
          :replace-fields="{ children: 'children', title: 'name', key: 'id' }"
          @check="onSelect"
        />
      </div>
      <div :class="$style.item">
        <a-tree
          v-model="appKeys"
          checkable
          disabled
          :tree-data="app"
          :replace-fields="{ children: 'children', title: 'name', key: 'id' }"
          @check="onSelect"
        />
      </div>
    </div>
    <div v-else :class="[$style.add]">
      <div v-if="isAdd" :class="$style.choose">
        在
        <BjSelect
          :value="copyId"
          :class="$style.input"
          key-field="id"
          placeholder="请选择角色"
          value-field="id"
          label-field="name"
          show-all
          :options="typeData"
          @change="typeChange"
        />
        的基础上编辑权限
      </div>
      <div :class="$style.addtree">
        <div :class="$style.item">
          <a-tree
            v-model="add.resourceKeys"
            checkable
            :auto-expand-parent="true"
            :tree-data="resource"
            :replace-fields="{ children: 'children', title: 'name', key: 'id' }"
            @check="onSelect"
          />
        </div>
        <div :class="$style.item">
          <a-tree
            v-model="add.systemKeys"
            checkable
            :auto-expand-parent="true"
            :tree-data="system"
            :replace-fields="{ children: 'children', title: 'name', key: 'id' }"
            @check="onSelect"
          />
        </div>
        <div :class="$style.item">
          <a-tree
            v-model="add.appKeys"
            checkable
            :auto-expand-parent="true"
            :tree-data="app"
            :replace-fields="{ children: 'children', title: 'name', key: 'id' }"
            @check="onSelect"
          />
        </div>
      </div>
    </div>
    <div v-if="edit" :class="$style.footer" :style="{ width: footWidth, left: left }">
      <BjButton class="mr-20" type="primary" @click="onSave()">保存</BjButton>
      <BjButton @click="onCancel()">取消</BjButton>
    </div>
    <bj-modal
      title="包含账号"
      :visible="visible"
      :body-style="{ 'max-height': '600px', position: 'relative', 'overflow-y': 'auto' }"
      @ok="visible = false"
      @cancel="visible = false"
    >
      <a-table :columns="columns" :data-source="list" :pagination="false" row-key="id">
        <template #cover="item">
          <div>{{ item.nickname }}</div>
          <div>{{ item.telephone }}</div>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="page"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :page-size.sync="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        />
      </div>
    </bj-modal>
  </div>
</template>

<script>
import _ from 'lodash'

import { roleService } from '@/service'

const service = new roleService()

export default {
  name: 'Home',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    user: {
      type: Array,
      default: () => [],
    },
    def: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resourceKeys: [],
      appKeys: [],
      systemKeys: [],
      selectedKeys: [],
      app: [],
      system: [],
      resource: [],
      visible: false,
      add: {
        name: null,
        describe: null,
        resourceKeys: [],
        appKeys: [],
        systemKeys: [],
      },
      // eslint-disable-next-line vue/no-unused-properties
      edit: false, // 添加编辑的状态
      isAdd: true,
      half: [],
      copyId: [],
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
    }
  },
  computed: {
    typeData() {
      return this.def.concat(this.user)
    },
    collapsed: {
      get() {
        return this.$store.state.collapsed
      },
      set(val) {
        this.$store.commit('COLLAPSED', val)
      },
    },
    sideChirdShow: {
      get() {
        return this.$store.state.sideChirdShow
      },
      set(val) {
        this.$store.commit('SIDECHIRDSHOW', val)
      },
    },
    footWidth() {
      let left = this.collapsed ? (this.sideChirdShow ? '260px' : '100px') : this.sideChirdShow ? '340px' : '180px'
      let w = 'calc(100% - ' + left + ')'
      return w
    },
    left() {
      let left = this.collapsed ? (this.sideChirdShow ? '240px' : '80px') : this.sideChirdShow ? '320px' : '160px'
      return left
    },
    columns() {
      return [
        { title: '姓名', dataIndex: 'nickname', width: 100 },
        {
          title: '账号',
          dataIndex: 'username',
        },
        { title: '添加时间', dataIndex: 'created_at', width: 200 },
      ]
    },
  },
  watch: {
    data() {
      this.appKeys = this.data.term_perms.app
      this.systemKeys = this.data.term_perms.system
      this.resourceKeys = this.data.term_perms.resource
    },
  },
  created() {
    this.getTree()
  },
  methods: {
    async getTree() {
      const { data } = await service.getTree()
      this.app = data.app
      this.system = data.system
      this.resource = data.resource
    },
    onSelect(selectedKeys, info) {
      this.half = this.half.concat(_.uniq(info.halfCheckedKeys))
      this.selectedKeys = selectedKeys
    },
    async onSave() {
      try {
        if (this.isAdd) {
          await service.roleAdd({
            name: this.add.name,
            describe: this.add.describe,
            perms: _.uniq(this.add.appKeys.concat(this.add.systemKeys).concat(this.add.resourceKeys).concat(this.half)),
            term_perms: this.add.appKeys.concat(this.add.systemKeys).concat(this.add.resourceKeys),
          })
          this.$message.success('添加成功')
        } else {
          await service.roleEdit({
            role_id: this.data.id,
            name: this.add.name,
            describe: this.add.describe,
            perms: _.uniq(this.add.appKeys.concat(this.add.systemKeys).concat(this.add.resourceKeys).concat(this.half)),
            term_perms: this.add.appKeys.concat(this.add.systemKeys).concat(this.add.resourceKeys),
          })
          this.$message.success('修改成功')
        }
        this.edit = false
        this.$emit('refresh')
      } catch (e) {}
    },
    onCancel() {
      this.edit = false
    },
    async onInclude() {
      try {
        const { data } = await service.roleUserList({
          role_id: this.data.id,
          role_type: this.data.type,
          page: this.page,
          page_size: this.pageSize,
        })
        this.list = data.record
        this.total = data.total
      } catch (e) {}
      this.visible = true
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
    onEdit() {
      this.edit = true
      this.isAdd = false
      this.half = []
      this.add.name = this.data.name
      this.add.describe = this.data.describe
      this.add.appKeys = this.data.term_perms.app
      this.add.systemKeys = this.data.term_perms.system
      this.add.resourceKeys = this.data.term_perms.resource
    },
    // eslint-disable-next-line vue/no-unused-properties
    onAdd() {
      this.add.name = null
      this.add.describe = null
      this.add.appKeys = []
      this.add.systemKeys = []
      this.add.resourceKeys = []
      this.isAdd = true
      this.edit = true
      this.half = []
      this.copyId = []
    },
    async onDelete() {
      try {
        await service.roleDelete({
          role_id: this.data.id,
        })
        this.$message.success('删除成功')
        this.$emit('ondelete')
      } catch (e) {}
    },
    typeChange(e) {
      this.copyId = e
      this.typeData.map(item => {
        if (e === item.id) {
          this.add.appKeys = item.term_perms.app
          this.add.systemKeys = item.term_perms.system
          this.add.resourceKeys = item.term_perms.resource
        }
      })
    },
  },
}
</script>

<style lang="less" module>
.detail {
  .header {
    background: #fff;
    width: 100%;
    height: 80px;
    display: flex;
    padding: 20px;

    .icon {
      font-size: 20px;
      color: #000;
      margin-right: 6px;
      position: relative;
      top: -4px;
    }

    .name {
      font-size: 16px;
      color: #000;
      flex: 1;

      p {
        margin-bottom: 4px;
      }

      .desc {
        font-size: 12px;
        color: #5c5c5c;
      }
    }

    .btn {
      color: @primary-color;
      font-size: 14px;
      cursor: pointer;

      i {
        font-size: 18px;
        vertical-align: -4px;
      }
    }
  }

  .edit {
    background: #fff;
    width: 100%;
    height: 220px;
    padding: 20px;

    .box {
      width: 740px;
    }

    .area {
      position: relative;

      .num {
        position: absolute;
        right: 10px;
        bottom: 10px;
        color: #5c5c5c;
      }
    }
  }

  .tree {
    background: #fff;
    margin-top: 20px;
    display: flex;
    padding: 20px 0;
    min-height: calc(100vh - 362px);

    .item {
      width: 33.3%;
      padding: 0 20px;
      border-right: 1px solid #eee;
    }

    .item:last-child {
      border: none;
    }
  }

  .add {
    padding-bottom: 60px;
    background: #fff;
    margin-top: 20px;
    padding: 20px 0 50px;
    min-height: calc(100vh - 362px);

    .choose {
      width: 100%;
      padding: 0 20px;
      display: flex;
      align-items: center;

      .input {
        width: 220px;
        margin: 0 12px;
      }

      :global {
        .ant-form-item {
          margin-bottom: 0;
        }
      }
    }

    .addtree {
      display: flex;
      margin-top: 20px;

      .item {
        width: 33.3%;
        padding: 0 20px;
        border-right: 1px solid #eee;
      }

      .item:last-child {
        border: none;
      }
    }
  }

  .footer {
    position: fixed;
    width: 800px;
    height: 60px;
    box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.05);
    text-align: center;
    bottom: 0;
    background: #fff;
    line-height: 60px;
  }
}
</style>
